<template>
  <div class="component-wrap">
    <a-tabs class="mine-tabs" v-model:activeKey="activeKey" @change="switchTab">
      <a-tab-pane :key="tab.key" :tab="tab.name" v-for="tab in tabs">
        <div class="filter-wrap" id="pageTop">
          <!-- 类别 -->
          <ListFilter
            only
            :name="$t('XB_Category')"
            :dataSource="typesOption"
            @onChange="switchTypes"
          />
        </div>
        <a-list
          class="demo-loadmore-list"
          :loading="loading"
          item-layout="horizontal"
          :data-source="dataList"
        >
          <template #loadMore>
            <div class="load-more">
              <a-spin v-if="loading" />
              <div v-else-if="dataList.length <= 0"></div>
              <div v-else-if="finished">{{ $t("no_more") }}</div>
              <!-- 没有更多了 -->
              <a-button
                v-else
                type="primary"
                @click="loadMore"
                class="load-btn"
              >
                {{ $t("load_more") }}
                <!-- 加载更多 -->
              </a-button>
            </div>
          </template>
          <template #renderItem="{ item }">
            <a-list-item>
              <a-list-item-meta>
                <template #title>
                  <OpenData type="userName" :openid="item.createUser" />
                  <div class="comment-medal" v-if="item.mediaImage && companyInfo.menu.includes('medal')">
                    <div class="medal-name">
                      <img draggable="false" :src="item.mediaImage" alt="" />
                      {{ item.medalName }}
                    </div>
                  </div>
                  <span class="pl-icon" v-if="tab.key == '1'">
                    <a href="javascript:;">
                      <!-- 确定要删除该条评论/回复吗 -->
                      <a-popconfirm
                        :title="
                          tab.key == '1'
                            ? $t('mine.sure_delete_comment')
                            : $t('mine.sure_delete_reply')
                        "
                        @confirm="del(item.commentId)"
                      >
                        <DeleteOutlined style="color: #e8673e" />
                      </a-popconfirm>
                    </a>
                  </span>
                </template>
                <template #avatar>
                  <a-avatar :src="item.portrait" :size="54" />
                </template>
                <template #description>
                  <div class="fontStyle" v-if="isShowDepart && item.departName">
                    {{ item.departName }}
                  </div>
                  <div class="p content">{{ item.content }}</div>
                  <div class="p">
                    <span class="time">
                      <a-tooltip
                        :title="moment().format('YYYY-MM-DD HH:mm:ss')"
                      >
                        <span>{{
                          moment(item.createTime * 1000).fromNow()
                        }}</span>
                      </a-tooltip>
                    </span>
                    <div
                      class="pl-reply"
                      v-if="item.list && item.list.length > 0"
                    >
                      <CaretUpOutlined
                        style="
                          position: absolute;
                          top: -13px;
                          left: 15px;
                          font-size: 18px;
                          color: #f4f4f4;
                        "
                      />
                      <a-list-item-meta
                        v-for="(rItem, rIndex) in item.list"
                        :key="rIndex"
                      >
                        <template #title>
                          <OpenData
                            type="userName"
                            :openid="rItem.createUser"
                          />
                          <div class="comment-medal" v-if="rItem.mediaImage && companyInfo.menu.includes('medal')">
                            <div class="medal-name">
                              <img
                                draggable="false"
                                :src="rItem.mediaImage"
                                alt=""
                              />
                              {{ rItem.medalName }}
                            </div>
                          </div>
                          <span class="pl-icon" v-if="tab.key == '2'">
                            <a href="javascript:;">
                              <!-- 确定要删除该条评论/回复吗 -->
                              <a-popconfirm
                                :title="
                                  tab.key == '1'
                                    ? $t('mine.sure_delete_comment')
                                    : $t('mine.sure_delete_reply')
                                "
                                @confirm="del(item.commentId)"
                              >
                                <DeleteOutlined style="color: #e8673e" />
                              </a-popconfirm>
                            </a>
                          </span>
                        </template>
                        <template #avatar>
                          <a-avatar :src="rItem.portrait" :size="54" />
                        </template>
                        <template #description>
                          <div
                            class="fontStyle"
                            v-if="isShowDepart && rItem.departName"
                          >
                            {{ rItem.departName }}
                          </div>
                          <div class="p content">{{ rItem.content }}</div>
                          <div class="p">
                            <span class="time">
                              <a-tooltip
                                :title="moment().format('YYYY-MM-DD HH:mm:ss')"
                              >
                                <span>{{
                                  moment(rItem.createTime * 1000).fromNow()
                                }}</span>
                              </a-tooltip>
                            </span>
                          </div>
                        </template>
                      </a-list-item-meta>
                    </div>
                    <div class="pl-source" @click="clickJump(item)">
                      <img
                        :src="
                          item.resourceImg ||
                          'http://datadev.tgtrain.cn/default/cover/4.jpg'
                        "
                      />
                      <div class="detail">
                        <p class="title">
                          {{ item.resourceName }}
                        </p>
                        <div class="type">
                          {{ getDetailType(item.resourceType) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { getCurrentInstance, onMounted, reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import { useRouter } from "vue-router";
import { getCommentList, batchDelComment, getReplyList } from "@/api/user";
import { getDetailType } from "@/utils/business";
import moment from "moment";
import ls from "@/utils/local-storage";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    ListFilter,
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      tabs: [
        { key: "1", name: $t("Lab_MyInteract_MyComment") }, // 我的评论
        { key: "2", name: $t("Lab_MyInteract_MyReplay") }, // 我的回复
      ],
      activeKey: "1",
      typesOption: [],
      searchParams: {
        page: 1,
        pageSize: 15,
        key: "",
        types: [],
        order: 1,
      },
      dataList: [],
      loading: true,
      finished: false,
      Total: 0,
      companyInfo: ls.get("companyInfo") || {},
    });

    if (state.companyInfo.menu.includes("knowledge")) {
      state.typesOption.push({ id: 1, name: $t("Pub_Tab_Knowledge") });
      // 知识
    }
    if (state.companyInfo.menu.includes("course")) {
      state.typesOption.push({ id: 2, name: $t("CM_Curriculum") });
      // 课程
    }
    if (state.companyInfo.menu.includes("news")) {
      state.typesOption.push({ id: 10, name: $t("Lab_News_News") });
      // 新闻
    }
    if (state.companyInfo.menu.includes("project")) {
      state.typesOption.push({ id: 34, name: $t("LB_Mysinup_Project") });
      // 项目
    }
    state.typesOption.push({ id: 8, name: $t("Pub_Tab_CT") });
    // 面授
    if (state.companyInfo.menu.includes("package")) {
      state.typesOption.push({ id: 35, name: $t("cm_package") });
      // 培训包
    }
    if (state.companyInfo.menu.includes("newstaff")) {
      state.typesOption.push({ id: 37, name: $t("cm_newstaff") });
      // 新人培训
    }
    if (state.companyInfo.menu.includes("subject")) {
      state.typesOption.push({ id: 38, name: $t("cm_subject") });
      // 课程专题
    }

    onMounted(() => {
      getList();
    });

    //上拉加载
    const loadMore = () => {
      state.loading = true;
      // 异步更新数据
      setTimeout(() => {
        getList();
      }, 100);
    };

    const reloadList = () => {
      state.dataList = [];
      state.searchParams.page = 1;
      state.loading = true;
      state.finished = false;
      getList();
    };

    const getList = () => {
      let list = getCommentList;
      if (state.activeKey == "2") {
        list = getReplyList;
      }
      list(state.searchParams).then((res) => {
        if (res.ret === 0) {
          state.dataList.push.apply(state.dataList, res.data.list);
          //每次拿到数据后页数加1
          state.searchParams.page += 1;
          // 加载状态结束
          state.loading = false;
          state.Total = res.data.totals;
          // 数据全部加载完成
          if (state.dataList.length >= res.data.totals) {
            state.finished = true;
          }
        }
      });
    };
    const clickJump = (item) => {
      switch (item.resourceType) {
        case 1: //知识
          router.push({ path: "/doc/detail", query: { id: item.resourceId } });
          break;
        case 2: //课程
          router.push({
            path: "/course/detail",
            query: { id: item.resourceId },
          });
          break;
        case 8: //面授
          router.push({
            path: "/train/detail",
            query: { id: item.taskId, did: item.resourceId },
          });
          break;
        case 10: //新闻
          let urlStr = "/news/detail?id=" + item.resourceId;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(urlStr + "&ddtab=true");
          } else {
            window.location.href = urlStr;
          }
          break;
        case 34: //项目
          router.push({
            path: "/project/detail",
            query: { id: item.resourceId },
          });
          break;
        case 35: //培训包
          router.push({
            path: "/package/detail",
            query: { id: item.resourceId },
          });
          break;
        case 37: //新人培训
          router.push({
            path: "/newstaff/detail",
            query: { id: item.resourceId },
          });
          break;
      }
    };
    const switchTab = () => {
      reloadList();
    };
    const switchTypes = (arr) => {
      state.searchParams.types = arr[0] === 0 ? [] : arr;
      reloadList();
    };

    const del = (id) => {
      batchDelComment({ ids: [id] }).then((res) => {
        if (res.ret == 0) {
          proxy.$message.success("删除成功！");
          reloadList();
        } else {
          // proxy.$message.success("删除失败！");
        }
      });
    };
    const platformConfig = computed(() => store.getters.platformConfig);
    const isShowDepart = computed(
      () =>
        platformConfig.value.useComment == 1 &&
        platformConfig.value.commentDisplayDepart == 1
    );

    return {
      ...toRefs(state),
      ListFilter,
      getDetailType,
      switchTab,
      switchTypes,
      moment,
      loadMore,
      clickJump,
      del,
      platformConfig,
      isShowDepart,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  min-height: 100%;
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);

  .mine-tabs {
    ::v-deep(.ant-tabs-bar) {
      border-bottom: 1px solid #f4f4f4;

      .ant-tabs-tab {
        padding: 12px 0 16px;
      }

      .ant-tabs-ink-bar {
        height: 4px;
        border-radius: 2px;
      }
    }
  }

  .component-tit {
    font-size: 20px;
    line-height: 26px;
    color: #333;
    font-weight: 600;
    padding: 16px 0 12px;
  }

  .filter-wrap {
    padding-bottom: 12px;

    .filter {
      padding: 5px 0;
    }
  }
}
::v-deep(.ant-list-item) {
  border-bottom-color: #f4f4f4;
  &:first-child {
    padding-top: 16px;
  }
  &:last-child {
    border-bottom: none !important;
  }
  .ant-list-item-meta-avatar {
    margin-right: 14px;
  }
  .ant-list-item-meta-title {
    margin-bottom: 2px;
    color: #333;
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
  }
  &.ant-list-split {
    border-bottom-color: #f4f4f4;
  }
}
.pl-icon {
  font-size: 15px;
  .fr();
  a {
    color: #999999;
    margin-left: 40px;
  }
}
.p {
  margin-bottom: 7px;
  .time {
    color: #999;
    font-size: 14px;
    line-height: 26px;
  }
  textarea.ant-input {
    margin: 15px 0;
  }
}
.p:nth-last-child(1) {
  margin: 0;
}
.p.content {
  color: #333;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 2px;
}
.pl-reply {
  position: relative;
  padding: 12px;
  background: #f5f7fb;
  margin-top: 12px;
  border-radius: 8px;
  .ant-list-item-meta:not(:last-child) {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e3f1fd;
  }
}
.pl-source {
  background: #f5f7fb;
  border-radius: 8px;
  padding: 12px;
  margin-top: 14px;
  cursor: pointer;
  position: relative;
  .mixinFlex(space-between; center);
  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 14px;
    width: 10px;
    height: 10px;
    background-color: #f5f7fb;
    transform: rotate(45deg);
  }
  img {
    width: 144px;
    height: 94px;
    border-radius: 4px;
    margin-right: 16px;
    vertical-align: top;
    display: inline-block;
  }
  .detail {
    display: inline-block;
    width: calc(100% - 156px);
    p {
      margin: 0;
    }
    .title {
      color: #333;
      font-size: 16px;
      font-weight: bold;
      .mixinEllipsis(44px, 2);
      line-height: 22px;
    }
    .type {
      color: @color-theme;
      border: 1px solid @color-theme;
      line-height: 22px;
      height: 22px;
      padding: 0 6px;
      border-radius: 2px;
      box-sizing: border-box;
      display: inline-block;
      margin-top: 12px;
      font-size: 14px;
    }
  }
}
.ant-list-item {
  padding: 30px 0;
}
.load-more {
  text-align: center;
  margin-top: 80px;
  height: 40px;
  line-height: 40px;
  color: "#BCBCBC";
  .load-btn {
    border-radius: 4px;
    background-color: #377dff;
  }
}
.fontStyle {
  color: #448bf7;
}
.departmentGap {
  padding: 0 3px;
}
::v-deep(.ant-list-item-meta-avatar) {
  position: relative;
}
.comment-medal {
  padding-left: 13px;
  margin-left: 5px;
  .mixinFlex(flex-start, center);
  display: inline-flex;
  .medal-name {
    display: inline-block;
    min-width: 52px;
    height: 16px;
    background: linear-gradient(
      90deg,
      rgba(252, 106, 43, 1) 0%,
      rgba(222, 205, 58, 1) 100%
    );
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    padding: 0 8px 0 14px;
    border-radius: 0 8px 8px 0;
    position: relative;
    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: -13px;
      top: -4px;
    }
  }
}
</style>
